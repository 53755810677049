// サイト全体で共通のウェブフォント
@import "fonts";

// サイト全体で共通のアニメーション
@import "animation";

// サイト全体で共通の設定
@import "variables";

// サイト全体で共通の関数
@import "functions";

// サイト全体で共通のミックスイン
@import "mixins";


// 印刷時のスタイル

body {
  width: $container-max-width + 310;
  transform: scale(0.8);
  transform-origin: 0 0;
}
