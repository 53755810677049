@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700,900&subset=japanese");
@import url("https://fonts.googleapis.com/css2?family=Odibee+Sans&display=swap");
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body {
  width: 1590px;
  transform: scale(0.8);
  transform-origin: 0 0;
}
